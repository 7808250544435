import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_KEY,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

new Vue({
  router,
  store,
  render: h => h(App),
  methods: {
  },
  mounted() {
  },
  created() {
    // Don't trigger login automatically, this is an antipattern\
    // It especially creates problems since we open a window with javascript
    // (this as any).$doTheLogin();

    // Instead, ready current login state from local storage and reload when authenticated
    (this as any).checkLoginState();
  },
}).$mount('#app');
