import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';
import DialogAddeventdate from '@/components/dialog-adddatetime/dialog-adddatetime.vue';
import DialogAddSponsor from '@/components/dialog-addsponsor/dialog-addsponsor.vue';
import DialogEditSponsor from '@/components/dialog-editsponsor/dialog-editsponsor.vue';
import Sponsor from '@/interfaces/sponsor';
import Event from '@/interfaces/event';

@Component({
  name: 'EventDetails',
  components: {
    DialogAddeventdate,
    DialogAddSponsor,
    DialogEditSponsor,
  },
  props: {
    event: {},
  },
  data() {
    return {
      selectedSponsor: null,
      showEventMenu: false,
      snackbarEventRemoved: false,
      snackbarEventDateRemoved: false,
      shootersIsOpen: false,
      sessionsIsOpen: false,
    };
  },
  methods: {
    deleteEventDate(eventDateId: number) {
      Axios
        .delete(`${this.$store.state.apiUrl}/eventdates/${eventDateId}`, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarEventDateRemoved = true;
          (this as any).$getEvents();

          window.setTimeout(() => {
            this.$props.event.eventDates = this.$store.state.events.find((el: any) => el.eventId === this.$props.event.eventId).eventDates;
          }, 200);
        })
        .catch((e) => {
          console.error('Error deleting EventDate: ', e);
        });
    },
    deleteEventSponsor(eventSponsorId: number) {
      Axios
        .delete(`${this.$store.state.apiUrl}/sponsors/${eventSponsorId}`, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarEventDateRemoved = true;
          (this as any).$getEvents();
          (this as any).$getSponsors();
        })
        .catch((e) => {
          console.error('Error deleting Sponsor: ', e);
        });
    },
    editEventSponsor(sponsor: Sponsor) {
      this.$store.state.isEditEventSponsorDialogOpen = true;
      this.$data.selectedSponsor = sponsor;
    },
    formatDate(inDate: string) {
      const date = new Date(inDate);

      return date.toLocaleDateString('de-DE', {
        // weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    deleteEvent(event: any) {
      Axios
        .delete(`${this.$store.state.apiUrl}/events/${event.eventId}`, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarEventRemoved = true;
          (this as any).$getEvents();
        })
        .catch((e) => {
          console.error('Error deleting Event: ', e);
        });
    },
    editEvent(event: any) {
      this.$store.state.isEditEventDialogOpen = true;
    },
    blobToDataURL(blob, callback) {
      const a = new FileReader();
      a.onload = function (e) { callback((e as any).target.result); };
      a.readAsDataURL(blob);
    },
    animateDoubleDetail(which) {
      const eventDetail = document.querySelector('#container-events-detail') as HTMLElement;
      const eventDoubleDetail = document.querySelector(`#container-events-detail-detail-${which}`) as HTMLElement;

      eventDetail.style.width = '40%';
      eventDoubleDetail.style.display = 'block';
      eventDoubleDetail.style.width = '30%';
    },
    showAllShooters() {
      (this as any).animateDoubleDetailBack();
      this.$data.sessionsIsOpen = false;

      if (!this.$data.shootersIsOpen) {
        window.setTimeout(() => {
          (this as any).animateDoubleDetail('shooters');
        }, 200);

        this.$data.shootersIsOpen = true;
      } else {
        this.$data.shootersIsOpen = false;
      }
    },
    showAllSessions() {
      (this as any).animateDoubleDetailBack();
      this.$data.shootersIsOpen = false;

      if (!this.$data.sessionsIsOpen) {
        window.setTimeout(() => {
          (this as any).animateDoubleDetail('sessions');
        }, 200);

        this.$data.sessionsIsOpen = true;
      } else {
        this.$data.sessionsIsOpen = false;
      }
    },
    animateDoubleDetailBack() {
      const eventDetail = document.querySelector('#container-events-detail') as HTMLElement;
      const eventDoubleDetail = document.querySelector('#container-events-detail-detail-shooters') as HTMLElement;
      const eventDoubleDetailS = document.querySelector('#container-events-detail-detail-sessions') as HTMLElement;

      eventDetail.style.width = '70%';
      eventDoubleDetail.style.width = '0';
      eventDoubleDetailS.style.width = '0';

      window.setTimeout(() => {
        eventDoubleDetail.style.display = 'none';
        eventDoubleDetailS.style.display = 'none';
      }, 200);
    },
  },
  computed: {

  },
  created() {
    this.$store.watch(() => this.$store.state.isEditEventDialogOpen, () => {
      if (this.$props.event) {
        if (this.$store.state.isEditEventDialogOpen === false) {
          window.setTimeout(() => {
            this.$props.event = this.$store.state.events.filter((e: Event) => e.eventId === this.$props.event.eventId)[0];
          }, 2000);
        }
      }
    });
  },
})
export default class EventDetails extends Vue {}
