import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';

const datepicker = require('vue-date-picker');

@Component({
  name: 'DialogAddeventdate',
  components: {
    datepicker,
  },
  props: {
    event: {},
  },
  data() {
    return {
      snackbarEventDateSaved: false,
      datePickerDate: '',
      calculatedFrom: () => 'test',
    };
  },
  methods: {
    checkIfFieldsFilled() {
      if (!document.querySelector('input[name="from"]') || !document.querySelector('input[name="until"]')) return true;

      const from = (document.querySelector('input[name="from"]')as HTMLInputElement).value;
      const until = (document.querySelector('input[name="until"]')as HTMLInputElement).value;
      if (!from && !until) return true;

      return false;
    },
    onAddEventDateDialogClosed(e) {
      if (e.action !== 'ok') return;

      const from = (document.querySelector('input[name="from"]') as HTMLInputElement).value;
      const until = (document.querySelector('input[name="until"]') as HTMLInputElement).value;

      const eventDate = {
        eventId: this.$props.event.eventId,
        from: `${from}T00:00:00`,
        until: `${until}T00:00:00`,
      };

      Axios
        .post(`${this.$store.state.apiUrl}/eventdates`, eventDate, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarEventDateSaved = true;
          (this as any).$getEvents();

          window.setTimeout(() => {
            this.$props.event.eventDates = this.$store.state.events.find((el: any) => el.eventId === this.$props.event.eventId).eventDates;
          }, 200);
        })
        .catch((err) => {
          console.error('Error in saving EventDate: ', err);
        });
    },
  },
  computed: {

  },
  created() {

  },
})
export default class DialogAddeventdate extends Vue {}
