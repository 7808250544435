import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';
import EventDetails from '@/components/eventdetails/eventdetails.vue';
import DialogEditevent from '@/components/dialog-editevent/dialog-editevent.vue';
import Event from '@/interfaces/event';

@Component({
  name: 'Events',
  components: {
    EventDetails,
    DialogEditevent,
  },
  props: {
  },
  data() {
    return {
      selectedEvent: undefined,
      selectedEventShooters: undefined,
      selectedEventSessions: undefined,
    };
  },
  methods: {
    blobToDataURL(blob, callback) {
      const a = new FileReader();
      a.onload = (e) => { callback((e as any).target.result); };
      a.readAsDataURL(blob);
    },
    animateDoubleDetailBack() {
      const eventDetail = document.querySelector('#container-events-detail') as HTMLElement;
      const eventDoubleDetail = document.querySelector('#container-events-detail-detail-shooters') as HTMLElement;
      const eventDoubleDetailS = document.querySelector('#container-events-detail-detail-sessions') as HTMLElement;

      eventDetail.style.width = '70%';
      eventDoubleDetail.style.width = '0';
      eventDoubleDetailS.style.width = '0';

      window.setTimeout(() => {
        eventDoubleDetail.style.display = 'none';
        eventDoubleDetailS.style.display = 'none';
      }, 200);
    },
    setEvent(event) {
      (this as any).animateDoubleDetailBack();
      this.$data.selectedEvent = event;

      (this as any).selectedEventShooters = [];
      (this as any).selectedEventSessions = [];

      Axios
        .get(`${this.$store.state.apiUrl}/events/${event.eventId}/shooters`, this.$store.state.httpHeaderSettings)
        .then((shooters) => {
          const eShooters = shooters.data.sort((a: any, b: any) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });

          (this as any).selectedEventShooters = eShooters;

          Axios
            .get(`${this.$store.state.apiUrl}/events/${event.eventId}/sessions`, this.$store.state.httpHeaderSettings)
            .then((sessions) => {
              let mergedData = [] as any[];

              mergedData = sessions.data.map((el: any) => {
                const obj = el;

                const shooter = eShooters.filter((s: any) => s.shooterId === obj.shooterId);

                if (shooter) obj.shooter = shooter[0];

                return obj;
              });

              mergedData = mergedData.sort((a: any, b: any) => {
                if (a.shooter.name < b.shooter.name) return -1;
                if (a.shooter.name > b.shooter.name) return 1;
                return 0;
              });

              (this as any).selectedEventSessions = mergedData;
            })
            .catch((e) => {
              console.error('Error in getting Sessions from Event: ', e);
            });
        })
        .catch((e) => {
          console.error('Error in getting Shooters from Event: ', e);
        });
    },
  },
  computed: {
    vGetEvents() {
      return this.$store.state.events.sort((ev: Event) => ev.eventId);
    },
  },
  created() {

  },
})
export default class Events extends Vue {}
