import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({
  name: 'DialogUnprivileged',
  data() {
    return {

    };
  },
  methods: {
    onUnprivilegedDialogClosed() {

    },
  },
  computed: {

  },
  created() {

  },
})
export default class DialogUnprivileged extends Vue {}
