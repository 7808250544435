import { Vue } from 'vue-property-decorator';

import Button from 'material-components-vue/dist/button';
import Card from 'material-components-vue/dist/card';
import Checkbox from 'material-components-vue/dist/checkbox';
import Chips from 'material-components-vue/dist/chips';
import Dialog from 'material-components-vue/dist/dialog';
import Drawer from 'material-components-vue/dist/drawer';
import Elevation from 'material-components-vue/dist/elevation';
import Fab from 'material-components-vue/dist/fab';
import FloatingLabel from 'material-components-vue/dist/floating-label';
import FormField from 'material-components-vue/dist/form-field';
import GridList from 'material-components-vue/dist/grid-list';
import Icon from 'material-components-vue/dist/icon';
import IconButton from 'material-components-vue/dist/icon-button';
import ImageList from 'material-components-vue/dist/image-list';
import LayoutGrid from 'material-components-vue/dist/layout-grid';
import LinearProgress from 'material-components-vue/dist/linear-progress';
import LineRipple from 'material-components-vue/dist/line-ripple';
import List from 'material-components-vue/dist/list';
import Menu from 'material-components-vue/dist/menu';
import NotchedOutline from 'material-components-vue/dist/notched-outline';
import Radio from 'material-components-vue/dist/radio';
import Ripple from 'material-components-vue/dist/ripple';
import Select from 'material-components-vue/dist/select';
import Shape from 'material-components-vue/dist/shape';
import Slider from 'material-components-vue/dist/slider';
import Snackbar from 'material-components-vue/dist/snackbar';
import Switch from 'material-components-vue/dist/switch';
import Tabs from 'material-components-vue/dist/tabs';
import TextField from 'material-components-vue/dist/text-field';
import Theme from 'material-components-vue/dist/theme';
// import Toolbar from 'material-components-vue/dist/toolbar';
import TopAppBar from 'material-components-vue/dist/top-app-bar';
import Typography from 'material-components-vue/dist/typography';

Vue.use(Button);
Vue.use(Card);
Vue.use(Checkbox);
Vue.use(Chips);
Vue.use(Dialog);
Vue.use(Drawer);
Vue.use(Elevation);
Vue.use(Fab);
Vue.use(FloatingLabel);
Vue.use(FormField);
Vue.use(GridList);
Vue.use(Icon);
Vue.use(IconButton);
Vue.use(ImageList);
Vue.use(LayoutGrid);
Vue.use(LinearProgress);
Vue.use(LineRipple);
Vue.use(List);
Vue.use(Menu);
Vue.use(NotchedOutline);
Vue.use(Radio);
Vue.use(Ripple);
Vue.use(Select);
Vue.use(Shape);
Vue.use(Slider);
Vue.use(Snackbar);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(TextField);
Vue.use(Theme);
// Vue.use(Toolbar);
Vue.use(TopAppBar);
Vue.use(Typography);
