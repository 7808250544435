import { Component, Vue } from 'vue-property-decorator';
import Range from '@/interfaces/range';

import RangeDetails from '@/components/rangedetails/rangedetails.vue';
import DialogEditrange from '@/components/dialog-editrange/dialog-editrange.vue'

@Component({
  name: 'Ranges',
  components: {
    RangeDetails,
    DialogEditrange,
  },
  props: {
  },
  data() {
    return {
      snackbarRangeRemoved: false,
      currentRange: '',
      selectedRange: undefined,
      selectedEvent: undefined,
    };
  },
  methods: {
    blobToDataURL(blob, callback) {
      const a = new FileReader();
      a.onload = function (e) { callback((e as any).target.result); };
      a.readAsDataURL(blob);
    },
  },
  computed: {
    vGetRanges() {
      return this.$store.state.ranges.sort((a: Range, b: Range) => a.name.localeCompare(b.name));
    },
  },
  created() {

  },
})
export default class Ranges extends Vue {}
