/* eslint-disable no-restricted-syntax */
import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';

@Component({
    name: 'Landing',
    data: function() {
        return {
            auth: null
        };
    },
    methods: {
        checkIfApiOnline() {
            Axios.get(`${this.$store.state.apiUrl}/online`, this.$store.state.httpHeaderSettings)
                .then(isonline => {})
                .catch(err => {
                    if (err.message === 'Network Error') {
                        alert('Der Server scheint offline zu sein. Bitte versuchen Sie es später erneut');
                        this.$router.push('/');
                    }
                });
        },
        async login() {
            try {
                await (this as any).$doTheLogin();
            } catch(e) {
                console.error("login somehow failed")
            }

            this.$router.push('/dashboard');
        },
    },
    computed: {}
})
export default class Landing extends Vue {}
