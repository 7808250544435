import { Component, Vue } from 'vue-property-decorator';
import { BlobServiceClient } from '@azure/storage-blob';
import Axios from 'axios';

@Component({
  name: 'DialogEditevent',
  props: {
    selectedEvent: {},
  },
  data() {
    return {
      uploadFileName: '',
      chosenFile: null,
      imageSource: null,
      eventName: '',
      eventWebsite: '',
      eventCoatOfArms: '',
      snackbarEventSaved: false,
      eventIsPublic: null,
      eventAdmin: '',
    };
  },
  methods: {
    async onEventEditDialogClosed(e: any) {
      if (e.action !== 'ok') {
        return;
      }

      let imageLink = '';

      if (this.$data.chosenFile != null) {
        try {
          await (this as any).uploadFile();
          imageLink = `https://scoremedia.blob.core.windows.net/${this.$store.state.storageContainerName}/${this.$data.uploadFileName}`;
        } catch (err) {
          alert('Error in uploading file');
          console.error(err);
        }
      } else if (!this.$data.chosenFile && this.$data.imageSource) {
        imageLink = this.$props.selectedEvent.image;
      } else if (!this.$data.imageSource) {
        imageLink = '';
      }

      const newEvent = {
        eventId: this.$props.selectedEvent.eventId,
        name: this.$data.eventName,
        website: this.$data.eventWebsite,
        isPublic: this.$data.eventIsPublic,
        coatOfArms: this.$data.eventCoatOfArms,
        shootingRangeId: 0,
        image: imageLink,
        adminUser: this.$data.eventAdmin,
      };

      Axios
        .put(`${this.$store.state.apiUrl}/events`, newEvent, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarEventSaved = true;
          (this as any).getEvents();
        })
        .catch((err) => {
          console.error('Error in saving Event: ', err);
        });
    },
    getEvents() {
      Axios
        .get(`${this.$store.state.apiUrl}/events`, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$store.state.events = response.data;
        })
        .catch((e) => {
          console.error('Error in getting Events: ', e);
        });
    },
    filterRanges() {
      if (this.$data.rangeFilter === '') {
        this.$data.filteredRanges = this.$store.state.ranges;
      } else {
        this.$data.filteredRanges = this.$store.state.ranges.filter((r: any) => r.name.toLowerCase().includes(this.$data.rangeFilter.toLowerCase()));
      }
    },
    updateFile(evnt) {
      const file = evnt.target.files[0];

      if (file.size >= 20000000) { // == 20MB
        alert('Das Bild muss kleiner als 20MB sein.');
        return;
      }

      this.$data.chosenFile = file;
      const urlCreator = window.URL || window.webkitURL;
      this.$data.imageSource = urlCreator.createObjectURL(file);
    },
    async uploadFile() {
      const blobServiceClient = new BlobServiceClient(this.$store.state.storageSecret);
      const file = this.$data.chosenFile;

      const containerClient = blobServiceClient.getContainerClient(this.$store.state.storageContainerName);
      const content = file;
      const blobName = `${new Date().getTime()}-${file.name}`;
      this.$data.uploadFileName = blobName;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const uploadBlobResponse = await blockBlobClient.uploadBrowserData(content);
    },
    deleteImage() {
      this.$data.imageSource = '';
    },
  },
  computed: {

  },
  watch: {
    selectedEvent(newVal, oldVal) {
      this.$data.eventName = newVal.name;
      this.$data.eventWebsite = newVal.website;
      this.$data.eventCoatOfArms = newVal.coatOfArms;
      this.$data.imageSource = newVal.image;
      this.$data.eventAdmin = newVal.adminUser;
      this.$data.eventIsPublic = newVal.isPublic ? newVal.isPublic : false;
    },
  },
  updated() {

  },
  created() {

  },
})
export default class DialogEditevent extends Vue {}
