import Vue from 'vue';
import Router from 'vue-router';
import Landing from './views/landing/landing.vue';
import Dashboard from './views/dashboard/dashboard.vue';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'landing',
      component: Landing,
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: '/userlist',
      name: 'userlist',
      component: Dashboard,
    },
    {
      path: '/ranges',
      name: 'ranges',
      component: Dashboard,
    },
    {
      path: '/events',
      name: 'events',
      component: Dashboard,
    },
    {
      path: '/userlist/detail/:userId',
      name: 'userlist/detail',
      component: Dashboard,
    },
  ],
});
