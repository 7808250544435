import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import Range from '@/interfaces/range';
import Event from '@/interfaces/event';
import Axios from 'axios';
import Sponsor from './interfaces/sponsor';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // UI Elements
    // Top bar title
    topBarTitle: '',

    // All ranges from the Polytronic API
    ranges: [] as Range[],
    // All events from the Polytronic API
    events: [] as Event[],
    // All sponsors from the Polytronic API
    sponsors: [] as Sponsor[],
    // The URL of the API where it is located
    apiUrl: process.env.VUE_APP_API_URL,
    // name of the azure blob storage container
    storageContainerName: process.env.VUE_APP_STORAGE_CONTAINER_NAME,
    // SAS string for the container
    storageSecret: process.env.VUE_APP_STORAGE_SECRET,
    // auth0 user data
    auth0User: {},
    // If the user is superuser or not
    isUserSuperuser: false,
    // all users
    allUsers: {},

    httpHeaderSettings: {},


    // Warnings and Errors
    // If not all required fields are filled in
    // showFillFieldWarning: false,

    // Dialogs
    // IF add range dialog is open
    isRangeDialogOpen: false,
    // If add event dialog is open
    isEventDialogOpen: false,
    // range unprivileged dialog
    isUnprivilegedDialogOpen: false,
    // event details (to edit events) dialog
    isEventDetailsDialogOpen: false,
    // event dates dialog
    isEventdateDialogOpen: false,
    // add sponsor dialog
    isEventSponsorDialogOpen: false,
    // edit sponsor dialog
    isEditEventSponsorDialogOpen: false,
    // Edit event dialog
    isEditEventDialogOpen: false,
    // Add Event Date dialog
    range: false,
    // Edit event dialog
    isEditRangeDialogOpen: false,


    // global auth0 object
    auth: null,
  },
  mutations: {

  },
  actions: {

  },
  getters: {

  },
});
