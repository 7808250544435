import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';

@Component({
  name: 'RangeDetails',
  props: {
    range: {},
  },
  data() {
    return {
      showRangeMenu: false,
    };
  },
  methods: {
    deleteRange(range: any) {
      this.$data.currentRange = range;

      Axios
        .delete(`${this.$store.state.apiUrl}/ranges/${range.shootingRangeId}`, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarRangeRemoved = true;
          (this as any).$getRanges();
        })
        .catch((e) => {
          console.error('Error deleting Range: ', e);
        });
    },
    blobToDataURL(blob, callback) {
      const a = new FileReader();
      a.onload = function (e) { callback((e as any).target.result); };
      a.readAsDataURL(blob);
    },
    test() {
      this.$store.state.isEditRangeDialogOpen = true;
    }
  },
  computed: {

  },
  created() {

  },
})
export default class RangeDetails extends Vue {}
