import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component({
  name: 'DialogAddrange',
  data() {
    return {
      // Range data
      rangeName: '',
      rangeCoatOfArms: '',
      rangeLanes: [],
      rangePrograms: [],
      rangeShooters: [],

      // Snackbar triggers
      snackbarRangeSaved: false,
    };
  },
  methods: {
    getRanges() {
      axios
        .get(`${this.$store.state.apiUrl}/ranges`, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$store.state.ranges = response.data;
        });
    },
    onRangeDialogClosed(e: any) {
      if (e.action !== 'ok') return;

      const newRange = {
        name: this.$data.rangeName,
        coatOfArms: this.$data.rangeCoatOfArms,
        lanes: this.$data.rangeLanes,
        programs: this.$data.rangePrograms,
        shooters: this.$data.rangeShooters,
        accessToken: Math.random().toString(32).slice(2),
      };

      axios
        .post(`${this.$store.state.apiUrl}/ranges`, newRange, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarRangeSaved = true;
          (this as any).getRanges();
        })
        .catch((err) => {
          console.error('Error in saving Range: ', err);
        });
    },
  },
  computed: {

  },
  created() {

  },
})
export default class DialogAddrange extends Vue {}
