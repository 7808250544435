import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';

@Component({
  name: 'DialogEditrange',
  props: {
    selectedRange: {},
  },
  data() {
    return {
      rangeName: '',
      rangeAdmin: '',
      rangeId: 0,
      snackbarRangeSaved: false,
    };
  },
  methods: {
    onRangeEditDialogClosed(e: any) {
      if (e.action !== 'ok') return;

      const newRange = {
        shootingRangeId: this.$data.rangeId,
        name: this.$data.rangeName,
        adminUser: this.$data.rangeAdmin,
      };
      
      Axios
        .put(`${this.$store.state.apiUrl}/ranges`, newRange, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarRangeSaved = true;
          (this as any).getRanges();
        })
        .catch((e) => {
          console.error('Error in saving Range: ', e);
        });
    },
    getRanges() {
      Axios
        .get(`${this.$store.state.apiUrl}/ranges`, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$store.state.ranges = response.data;
        })
        .catch((e) => {
          console.error('Error in getting Events: ', e);
        });
    },
  },
  watch: {
    selectedRange(newVal, oldVal) {
      this.$data.rangeId = newVal.shootingRangeId;
      this.$data.rangeName = newVal.name;
      this.$data.rangeAdmin = newVal.adminUser;
    },
  },
  computed: {

  },
  created() {

  },
})
export default class DialogEditevent extends Vue {}
