import createAuth0Client, { Auth0Client, PopupCancelledError, User } from '@auth0/auth0-spa-js';
import { Component, Vue } from 'vue-property-decorator';
import NavigationDuplicated from 'vue-router';
import Axios from 'axios';


// TODO: Rewrite as class to avoid constant (this as any) statements
@Component({
    name: 'Auth',
    data: function() {
        return {
            auth0Options: {
                domain: process.env.VUE_APP_AUTH0_DOMAIN,
                client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
                audience: process.env.VUE_APP_AUTH0_AUDIENCE,
                scope: process.env.VUE_APP_AUTH0_SCOPE
            },
            auth: undefined
        };
    },
    methods: {
        async getAuth(): Promise<Auth0Client> {
            if (!this.$data.auth) {
                this.$data.auth = await createAuth0Client(this.$data.auth0Options);
            }

            return this.$data.auth;
        },
        checkIfApiOnline(): void {
            Axios.get(`${this.$store.state.apiUrl}/online`, this.$store.state.httpHeaderSettings)
                .then(isonline => {})
                .catch(e => {
                    if (e.message === 'Network Error') {
                        alert('Der Server scheint offline zu sein. Bitte versuchen Sie es später erneut');
                        (this as any).reload();
                    }
                });
        },
        reload(): void {
            try {
                this.$router.replace('/');
            } catch (error) {
                if (error instanceof NavigationDuplicated) {
                    // Intentionally ignored
                } else {
                    throw error;
                }
            }
        },
        async $doTheLogin(): Promise<void> {
            (this as any).checkIfApiOnline();
            const auth = await (this as any).getAuth();

            try {
                await auth.loginWithPopup();
                await (this as any).tokenLogic();
            } catch (e) {
                // Ignore when login was cancelled
                if (e instanceof PopupCancelledError) return;
                // Show all other errors
                alert(e);
                console.error(e);
            }
        },
        async $logoutUser(): Promise<void> {
            const auth = await (this as any).getAuth();

            let logout_url = process.env.VUE_APP_LOGOUT_URL;
            if (process.env.NODE_ENV == 'development') {
                logout_url = 'http://localhost:8080/'
            }

            await auth.logout({ returnTo:  logout_url});
            (this as any).removeAuthFromLS();
        },
        async checkLoginState(): Promise<void> {
            const token = (this as any).getAuthFromLS();

            if (token) {
                const parsedToken = (this as any).parseToken(token);

                const tokenExpirationTime = parsedToken.exp;
                const nowTime = (Date.now() / 1000) | 0;

                // Because they are unix style timestamps (seconds elapsed since jan 1. 1970)
                // the comparison can be made like this
                const tokenIsExpired = nowTime > tokenExpirationTime
                if (tokenIsExpired) {
                    (this as any).reload();
                } else {
                    (this as any).setHTTPHeaders(token);
                    (this as any).setUserPermissionsFromToken(token);
                }
            } else {
                (this as any).reload();
            }
        },
        async tokenLogic(): Promise<void> {
            const auth = await (this as any).getAuth();

            const user = await auth.getUser();
            this.$store.state.auth0User = user as User;

            const accessToken = await auth.getTokenSilently({ audience: this.$data.auth0Options.audience });
            (this as any).setHTTPHeaders(accessToken);
            (this as any).setAuthToLS(accessToken);
            (this as any).setUserPermissionsFromToken(accessToken);
        },
        setUserPermissionsFromToken(token: string) {
            const parsedToken = (this as any).parseToken(token);
            (this as any).setUserPermissions(parsedToken.permissions);
        },
        setHTTPHeaders(token: string) {
            this.$store.state.httpHeaderSettings = { headers: { Authorization: `Bearer ${token}` } };
        },
        setUserPermissions(permissionsArray: Array<string>) {
            // Only allow superusers to see & edit shooting ranges
            if (permissionsArray.includes('superuser')) {
                this.$store.state.isUserSuperuser = true;
            }

            // Load shooting ranges & events available for user
            (this as any).initializeStore();

            // TODO: Restore unprivileged error message
            //this.$store.state.isUnprivilegedDialogOpen = true;
        },
        parseToken(rawToken): void {
            const accessToken = rawToken;
            var base64Url = accessToken.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );

            const parsedToken = JSON.parse(jsonPayload);
            return parsedToken;
        },
        getAuthFromLS(): string | null {
            const item = localStorage.getItem('ScoreLogin');

            if (!item) {
                return null;
            } else {
                return item;
            }
        },
        setAuthToLS(token: string): void {
            localStorage.setItem('ScoreLogin', token);
        },
        removeAuthFromLS(): void {
            localStorage.removeItem('ScoreLogin');
        },
        initializeStore(): void {
            (this as any).$getRanges();
            (this as any).$getEvents();
            (this as any).$getSponsors();
        },
        // TODO: Lots of duplicated code here, should be simplyfied
        $getRanges(): void {
            Axios.get(`${this.$store.state.apiUrl}/ranges`, this.$store.state.httpHeaderSettings)
                .then(response => {
                    this.$store.state.ranges = response.data;
                })
                .catch(e => {
                    console.error('Error in getting Ranges: ', e);
                });
        },
        $getEvents(): void {
            Axios.get(`${this.$store.state.apiUrl}/events`, this.$store.state.httpHeaderSettings)
                .then(response => {
                    this.$store.state.events = response.data;
                })
                .catch(e => {
                    console.error('Error in getting Events: ', e);
                });
        },
        $getSponsors(): void {
            Axios.get(`${this.$store.state.apiUrl}/sponsors`, this.$store.state.httpHeaderSettings)
                .then(response => {
                    this.$store.state.sponsors = response.data;
                })
                .catch(e => {
                    console.error('Error in getting Sponsors: ', e);
                });
        },
        $getUsers(): void {
            if (this.$store.state.allUsers.length !== undefined) return;

            if (!this.$store.state.httpHeaderSettings.headers) {
                (this as any).$doTheLogin();
            }

            Axios.get(`${this.$store.state.apiUrl}/users`, this.$store.state.httpHeaderSettings)
                .then(userResponse => {
                    this.$store.state.allUsers = userResponse.data;
                })
                .catch(e => {
                    console.error('Error while getting all Users: ', e);
                });
        }
    }
})
export default class Auth extends Vue {}
