import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';

@Component({
  name: 'UserDetail',
  data() {
    return {
      clickedUser: {},
    };
  },
  methods: {
    upgradeUser(userId: number) {
      alert(`upgrade user ${userId}`);
    },
  },
  computed: {
  },
  created() {
  },
})
export default class UserDetail extends Vue {}
