/* eslint-disable no-restricted-syntax */
import { Component, Vue } from 'vue-property-decorator';
import { BlobServiceClient } from '@azure/storage-blob';
import Axios from 'axios';

@Component({
  name: 'DialogAddevent',
  data() {
    return {
      uploadFileName: '',
      chosenFile: null,
      imageSource: null,
      eventName: '',
      eventWebsite: '',
      eventCoatOfArms: '',
      eventIsPublic: false,
      snackbarEventSaved: false,
      rangeIsActive: true,
      activeRange: {},
      rangeFilter: '',
      filteredRanges: [],
      selectOptions: [
        {
          text: 'SSV Lizenznummern',
          value: 'SsvLicenseNumbers',
        },
        {
          text: 'Eventnummern',
          value: 'EventShooterNumbers',
        },
      ],
      selected: '',
    };
  },
  methods: {
    async onEventDialogClosed(e: any) {
      if (e.action !== 'ok') {
        return;
      }

      if (this.$data.chosenFile != null) {
        try {
          await (this as any).uploadFile();
        } catch (err) {
          alert('Error in uploading file');
          console.error(err);
        }
      }

      const newEvent = {
        name: this.$data.eventName,
        coatOfArms: this.$data.eventCoatOfArms,
        isPublic: this.$data.eventIsPublic,
        image: (this.$data.chosenFile ? `https://scoremedia.blob.core.windows.net/${this.$store.state.storageContainerName}/${this.$data.uploadFileName}` : ''),
        shootingRangeId: this.$data.activeRange.shootingRangeId,
        identificationType: this.$data.selected,
        website: this.$data.eventWebsite,
      };

      Axios
        .post(`${this.$store.state.apiUrl}/events`, newEvent, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarEventSaved = true;
          (this as any).getEvents();
        })
        .catch((err) => {
          console.error('Error in saving Range: ', err);
        });
    },
    getEvents() {
      Axios
        .get(`${this.$store.state.apiUrl}/events`, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$store.state.events = response.data;
        })
        .catch((e) => {
          console.error('Error in getting Events: ', e);
        });
    },
    filterRanges() {
      if (this.$data.rangeFilter === '') {
        this.$data.filteredRanges = this.$store.state.ranges;
      } else {
        this.$data.filteredRanges = this.$store.state.ranges.filter((r: any) => r.name.toLowerCase().includes(this.$data.rangeFilter.toLowerCase()));
      }
    },
    updateFile(evnt) {
      const file = evnt.target.files[0];

      if (file.size >= 20000000) { // = 20MB
        alert('Das Bild muss kleiner als 20MB sein.');
        return;
      }

      this.$data.chosenFile = file;
      const urlCreator = window.URL || window.webkitURL;
      this.$data.imageSource = urlCreator.createObjectURL(file);
    },
    async uploadFile() {
      const blobServiceClient = new BlobServiceClient(this.$store.state.storageSecret);
      const file = this.$data.chosenFile;

      const containerClient = blobServiceClient.getContainerClient(this.$store.state.storageContainerName);
      const content = file;
      const blobName = `${new Date().getTime()}-${file.name}`;
      this.$data.uploadFileName = blobName;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const uploadBlobResponse = await blockBlobClient.uploadBrowserData(content);
    },
  },
  computed: {
  },
  async created() {
    window.setTimeout(() => {
      this.$data.filteredRanges = this.$store.state.ranges;
    }, 3000); // Quick Hack, niceify
  },
})
export default class DialogAddevent extends Vue {}
