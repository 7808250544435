import { Component, Vue } from 'vue-property-decorator';
import Sponsor from '@/interfaces/sponsor';
import { BlobServiceClient } from '@azure/storage-blob';
import Axios from 'axios';

@Component({
  name: 'DialogEditSponsor',
  components: {

  },
  props: {
    sponsor: {},
  },
  data() {
    return {
      sponsorName: '',
      sponsorKeyword: '',
      uploadFileName: '',
      chosenFile: null,
      imageSource: null,
      snackbarSponsorSaved: false,
      saveActive: true,
    };
  },
  methods: {
    checkIfFieldsFilled() {
      const nameField = document.querySelector('input[name="sponsor-name-e"]');
      const keyField = document.querySelector('input[name="sponsor-key-e"]');

      if (!nameField || !keyField) {
        this.$data.saveActive = false;
        return true;
      }

      const name = (nameField as HTMLInputElement).value;
      const key = (keyField as HTMLInputElement).value;

      if (!name || !key) {
        this.$data.saveActive = false;
        return true;
      }

      this.$data.saveActive = true;
      return false;
    },
    async onAddSponsorDialogClosed(e) {
      if (e.action !== 'ok') return;

      let imageLink = '';

      if (this.$data.chosenFile != null) {
        try {
          await (this as any).uploadFile();
          imageLink = `https://scoremedia.blob.core.windows.net/${this.$store.state.storageContainerName}/${this.$data.uploadFileName}`;
        } catch (err) {
          alert('Error in uploading file');
        }
      } else if (!this.$data.chosenFile && this.$props.sponsor.image) {
        imageLink = this.$props.sponsor.image;
      } else if (!this.$props.sponsor.image) {
        imageLink = '';
      }

      const newSponsor: Sponsor = {
        sponsorId: this.$props.sponsor.sponsorId,
        eventId: this.$props.sponsor.eventId,
        name: this.$props.sponsor.name,
        keyword: this.$props.sponsor.keyword,
        image: imageLink,
      };

      Axios
        .put(`${this.$store.state.apiUrl}/sponsors`, newSponsor, this.$store.state.httpHeaderSettings)
        .then((response) => {
          this.$data.snackbarSponsorSaved = true;
          (this as any).$getEvents();
          (this as any).$getSponsors();
          this.$data.imageSource = null;
          this.$data.chosenFile = null;
        })
        .catch((err) => {
          console.error('Error in saving Sponsor: ', err);
        });
    },
    updateFile(evnt) {
      const file = evnt.target.files[0];

      if (file.size >= 20000000) { // = 20MB
        alert('Das Bild muss kleiner als 20MB sein.');
        return;
      }
      this.$props.sponsor.image = '';
      this.$data.chosenFile = file;
      const urlCreator = window.URL || window.webkitURL;
      this.$data.imageSource = urlCreator.createObjectURL(file);
    },
    async uploadFile() {
      const blobServiceClient = new BlobServiceClient(this.$store.state.storageSecret);

      const file = this.$data.chosenFile;

      const containerClient = blobServiceClient.getContainerClient(this.$store.state.storageContainerName); // ToDo: Make it so it choses right container automatically per env
      const content = file;
      const blobName = `${new Date().getTime()}-${file.name}`;
      this.$data.uploadFileName = blobName;
      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const uploadBlobResponse = await blockBlobClient.uploadBrowserData(content);
    },
    deleteImage() {
      this.$props.sponsor.image = '';
      this.$data.chosenFile = null;
    },
  },
  computed: {

  },
  created() {

  },
})
export default class DialogEditSponsor extends Vue {}
