import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';
import DialogAddrange from '@/components/dialog-addrange/dialog-addrange.vue';
import DialogAddevent from '@/components/dialog-addevent/dialog-addevent.vue';
import DialogEditevent from '@/components/dialog-editevent/dialog-editevent.vue';
import DialogUnprivileged from '@/components/dialog-unprivileged/dialog-unprivileged.vue';
import Userlist from '@/views/userlist/userlist.vue';
import Userdetail from '@/views/userdetail/userdetail.vue';
import RangeDetails from '@/components/rangedetails/rangedetails.vue';
import EventDetails from '@/components/eventdetails/eventdetails.vue';
import Ranges from '@/components/ranges/ranges.vue';
import Events from '@/components/events/events.vue';

declare const ColorThief: any;

@Component({
    name: 'Dashboard',
    components: {
        DialogAddrange,
        DialogAddevent,
        DialogEditevent,
        DialogUnprivileged,
        Userlist,
        Userdetail,
        RangeDetails,
        EventDetails,
        Ranges,
        Events
    },
    data() {
        return {
            snackbarRangeRemoved: false,
            currentRange: '',
            selectedRange: undefined,
            selectedEvent: undefined
        };
    },
    methods: {
        blobToDataURL(blob, callback) {
            const a = new FileReader();
            a.onload = function(el) {
                callback((el as any).target.result);
            };
            a.readAsDataURL(blob);
        },
        showDetailMenu(e) {
            this.$data.showRangeMenu = true;
        },
        async logOutUser() {
            await (this as any).$logoutUser();

            this.$store.state.auth0User = {};
            this.$store.state.isUserSuperuser = false;
        },
        getRanges() {
            Axios.get(`${this.$store.state.apiUrl}/ranges`, this.$store.state.httpHeaderSettings)
                .then(response => {
                    this.$store.state.ranges = response.data;
                })
                .catch(e => {
                    console.error('Error in getting Ranges: ', e);
                });
        },
        getEvents() {
            Axios.get(`${this.$store.state.apiUrl}/events`, this.$store.state.httpHeaderSettings)
                .then(response => {
                    this.$store.state.events = response.data;
                })
                .catch(e => {
                    console.error('Error in getting Events: ', e);
                });
        },
        initializeRangeadmin() {
            (this as any).getRanges();
            (this as any).getEvents();
        },
        initializeSuperuser() {
            (this as any).getRanges();
            (this as any).getEvents();
        },
        handleActiveMenuItems() {
            document.querySelectorAll('.mdc-drawer .mdc-list-item').forEach(e => {
                e.classList.remove('mdc-list-item--activated');

                e.addEventListener('click', event => {
                    document.querySelectorAll('.mdc-drawer .mdc-list-item').forEach(el => {
                        el.classList.remove('mdc-list-item--activated');
                    });

                    (event.target as HTMLElement).classList.add('mdc-list-item--activated');
                });
            });
        }
    },
    computed: {
        vGetRanges() {
            return this.$store.state.ranges;
        },
        vGetEvents() {
            return this.$store.state.events;
        },
        vHasAnyPermission() {
            return this.$store.state.events.length > 0 || this.$store.state.ranges.length > 0;
        },
        vIsDashboard() {
            if (this.$route.path === '/dashboard') {
                this.$store.state.topBarTitle = 'Veranstaltungen & Schiessstände';
                return true;
            }
            return false;
        },
        vIsRanges() {
            if (this.$route.path === '/ranges') {
                this.$store.state.topBarTitle = 'Schiessstände';
                return true;
            }
            return false;
        },
        vIsEvents() {
            if (this.$route.path === '/events') {
                this.$store.state.topBarTitle = 'Veranstaltungen';
                return true;
            }
            return false;
        },
        vIsUserlist() {
            if (this.$route.path === '/userlist') {
                this.$store.state.topBarTitle = 'Benutzer';
                return true;
            }
            return false;
        },
        vIsUserdetail() {
            if (this.$route.path.includes('/userlist/detail/')) {
                return true;
            }
            return false;
        }
    },
    async created() {
        (this as any).handleActiveMenuItems();

        this.$store.watch(
            () => this.$store.state.events,
            () => {
                if (!this.$data.selectedEvent) return;

                const currentEvent = this.$data.selectedEvent;
                const newEvent = this.$store.state.events.filter((e: any) => e.eventId === currentEvent.eventId);

                this.$data.selectedEvent = newEvent[0];
            }
        );
    }
})
export default class Landing extends Vue {}
