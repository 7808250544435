import { Component, Vue } from 'vue-property-decorator';
import Axios from 'axios';

@Component({
  name: 'Userlist',
  data() {
    return {
      unprivilegedUsers: [],
      rangeadminUsers: [],
      superuserUsers: [],
    };
  },
  methods: {
  },
  computed: {
  },
  mounted() {
    // Failes at the moment and isn't used anyway

    // (this as any).$getUsers();

    // this.$store.watch(() => { return this.$store.state.allUsers; }, () => {
    //   this.$data.unprivilegedUsers = this.$store.state.allUsers
    //     .filter((user: any) => user.adminLevel == 'UNPRIVILEGED');

    //   this.$data.rangeadminUsers = this.$store.state.allUsers
    //     .filter((user: any) => user.adminLevel == 'RANGEADMIN');

    //   this.$data.superuserUsers = this.$store.state.allUsers
    //     .filter((user: any) => user.adminLevel == 'SUPERUSER');
    // });
  },
})
export default class Userlist extends Vue {}
