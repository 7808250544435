














import { Component, Vue } from 'vue-property-decorator';
import Auth from '@/mixins/Auth';
import CoatOfArms from '@/mixins/Coa';
import './theme';

Vue.mixin(Auth);
Vue.mixin(CoatOfArms);

  @Component({
    name: 'App',
  })
export default class App extends Vue { }
