import { Component, Vue } from 'vue-property-decorator';

declare const ColorThief: any;

@Component({
  name: 'COA',
  methods: {
    mapCoatOfArms(coatOfArms: string) {
      // temporary work-around for coats
      // example fo a coat for Gams SG:
      // http://ltescari.ch/swisscoats/sg/gams.jpg

      // workaround so it works with our current data structure

      let coa = '';

      if (coatOfArms === 'Switzerland') {
        coa = 'global/switzerland';
      } else if (coatOfArms === 'Dietikon') {
        coa = 'zh/dietikon';
      } else if (coatOfArms === 'Zürich') {
        coa = 'zh/zurich';
      } else if (coatOfArms === 'Kloten') {
        coa = 'zh/kloten';
      } else if (coatOfArms === 'Dübendorf') {
        coa = 'zh/dubendorf';
      } else if (coatOfArms === 'Aarau') {
        coa = 'ag/aarau';
      } else if (coatOfArms === 'Buchs') {
        coa = 'sg/buchs';
      } else if (coatOfArms === 'Gams') {
        coa = 'sg/gams';
      } else {
        coa = 'global/switzerland';
      }

      return coa;
    },
    $checkCoatOfArms(coatOfArms: string, identifyer: string, id: any) {
      /*
              Really ugly, have to redo sometime
            */
      const coatApi = 'https://ltescari.ch/swisscoats';
      const fullUrl = `${coatApi}/${(this as any).mapCoatOfArms(coatOfArms)}.jpg`;

      fetch(fullUrl)
        .then(res => res.blob())
        .then((blob) => {
          let data;
          (this as any).blobToDataURL(blob, (dataurl: any) => {
            const currentImage = document.querySelector(`#${identifyer}-${id}`) as HTMLElement;
            currentImage.setAttribute('src', dataurl);
          });
        });
    },
    $checkCoatOfArmsEvent(coatOfArms: string, identifyer: string, id: any) {
      const coatApi = 'https://ltescari.ch/swisscoats';
      const fullUrl = `${coatApi}/${(this as any).mapCoatOfArms(coatOfArms)}.jpg`;

      fetch(fullUrl)
        .then(res => res.blob())
        .then((blob) => {
          let data;
          (this as any).blobToDataURL(blob, (dataurl: any) => {
            const currentImage = document.querySelector('#eventdetail-header-coa') as HTMLElement;
            currentImage.setAttribute('src', dataurl);

            window.setTimeout(() => {
              const colorThief = new ColorThief();
              const color = colorThief.getColor(currentImage);
              const banner = ((currentImage as HTMLElement).closest('.eventdetail-header') as HTMLElement);
              const bannercolor = `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.1)`;

              // banner.style.backgroundImage = `linear-gradient(${bannercolor}, #ffffff)`;
              banner.style.background = bannercolor;
            }, 200);
          });
        });
    },
    $checkCoatOfArmsRange(coatOfArms: string, identifyer: string, id: any) {
      /*
                Really ugly, have to redo sometime
            */
      const coatApi = 'https://ltescari.ch/swisscoats';
      const fullUrl = `${coatApi}/${(this as any).mapCoatOfArms(coatOfArms)}.jpg`;

      fetch(fullUrl)
        .then(res => res.blob())
        .then((blob) => {
          let data;
          (this as any).blobToDataURL(blob, (dataurl: any) => {
            const currentImage = document.querySelector('#rangedetail-header-coa') as HTMLElement;
            currentImage.setAttribute('src', dataurl);

            window.setTimeout(() => {
              const colorThief = new ColorThief();
              const color = colorThief.getColor(currentImage);
              const banner = ((currentImage as HTMLElement).closest('.rangedetail-header') as HTMLElement);
              const bannercolor = `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.1)`;

              // banner.style.backgroundImage = `linear-gradient(${bannercolor}, #ffffff)`;
              banner.style.background = bannercolor;
            }, 200);
          });
        });
    },
  },
  created() {
  },
})
export default class COA extends Vue {}
